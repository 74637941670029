<template>
  <li v-if="!dropdown">
    <a :href="link" :target="target" v-on="$listeners" data-testid="button">
      <i :class="`fa fa-${icon}`" data-testid="icon"></i>
      <span
        :class="`label ${_badgeType}`"
        v-if="badgeNumber"
        data-testid="badge"
        >{{ badgeNumber }}</span
      >
    </a>
  </li>
  <DropdownButton
    v-else
    class="dropdown header-button"
    :defaultClass="false"
    :caret="false"
    tag="li"
    buttonTag="a"
    v-on="$listeners"
  >
    <i :class="`fa fa-${icon}`" data-testid="icon"></i>
    <span
      :class="`label ${_badgeType}`"
      v-if="badgeNumber"
      data-testid="badge"
      >{{ badgeNumber }}</span
    >

    <template #options>
      <li
        class="header"
        v-if="dropdownTitle || $slots.header"
        data-testid="header"
      >
        <slot name="header">{{ dropdownTitle }}</slot>
      </li>
      <li>
        <ul class="menu">
          <slot name="options">
            <li v-for="option in dropdownOptions" :key="option">
              <a
                href="#"
                @click="$emit('option:click', option)"
                data-testid="option"
                >{{ option }}</a
              >
            </li>
          </slot>
        </ul>
      </li>
      <li class="footer" v-if="$slots.footer" data-testid="footer">
        <slot name="footer"></slot>
      </li>
    </template>
  </DropdownButton>
</template>

<script>
import DropdownButton from "@/components/base/buttons/dropdown-button";

export default {
  name: "HeaderButton",
  components: { DropdownButton },
  props: {
    icon: {
      type: String,
      required: false,
      default: "bell-o"
    },
    badgeType: {
      type: String,
      required: false,
      default: "primary"
    },
    badgeNumber: {
      type: Number,
      required: false,
      default: 0
    },
    link: {
      type: String,
      required: false,
      default: "#"
    },
    target: {
      type: String,
      required: false,
      default: ""
    },
    dropdown: {
      type: Boolean,
      required: false,
      default: false
    },
    dropdownTitle: {
      type: String,
      required: false,
      default: ""
    },
    dropdownOptions: {
      type: Array,
      required: false,
      default: () => []
    }
  },
  computed: {
    _badgeType() {
      return !this.badgeType.includes("-")
        ? `label-${this.badgeType}`
        : this.badgeType;
    }
  }
};
</script>

<style scoped>
.header-button >>> a {
  cursor: pointer;
}
</style>
