<template>
  <ul class="sidebar-menu" data-widget="tree" data-auto-collapse-size="768">
    <li class="header">{{ $t("main_navigation") }}</li>
    <li
      v-for="item in options"
      v-bind:key="item.id"
      v-bind:class="{
        disabled: item.disabled,
        hidden: item.hidden,
        divisor: item.target == '-',
        treeview: item.options && item.options.length
      }"
    >
      <MenuItemLink
        :data-testid="normalizeLabel(item.label)"
        v-if="item.type == 'link' && item.target != '-'"
        v-bind:option="item"
        v-bind:multilevel="item.options && item.options.length ? true : false"
        @toggleTreeview="toggleTreeview(item.id)"
      ></MenuItemLink>
      <MenuItemPanel
        :data-testid="normalizeLabel(item.label)"
        v-else-if="item.target != '-'"
        v-bind:option="item"
        v-bind:multilevel="item.options && item.options.length ? true : false"
        :equipmentId="equipmentId"
        @toggleTreeview="toggleTreeview(item.id)"
      ></MenuItemPanel>
      <MenuTreeView
        v-if="item.options && item.options.length ? true : false"
        :items="item.options"
        :id="item.id"
        :equipmentId="equipmentId"
      />
    </li>
  </ul>
</template>

<script>
import MenuItemLink from "./menu-item-link";
import MenuItemPanel from "./menu-item-panel";
import MenuTreeView from "./menu-tree-view";
import MixinRuleValidation from "@/project/mixin-rule-validation";

export default {
  name: "Menu",
  mixins: [MixinRuleValidation],
  components: {
    MenuItemLink,
    MenuItemPanel,
    MenuTreeView
  },
  props: {
    equipmentId: {
      type: Number,
      required: true,
      default: 0
    },
    dashboard: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      hidden_in_path: ["/dashboard/profile"],
      openScreen: null
    };
  },
  computed: {
    contract() {
      return this.$store.getters["user/contract"] || null;
    },
    items: function() {
      var self = this;
      var dashboard = self.dashboard;
      var ret =
        (dashboard &&
          "page_side_bar" in dashboard &&
          "options" in dashboard.page_side_bar &&
          dashboard.page_side_bar.options.length &&
          dashboard.page_side_bar.options) ||
        [];
      return ret;
    },
    lastScreenId() {
      return this.$store.getters["dashboard/getRecentScreenId"];
    },
    options: function() {
      let contract = this.contract;
      if (!contract) return null;
      //contract["allowed_custom_screens"]=false; // for testing only
      //delete contract.allowed_custom_screens;
      const _parseOption = (option) => {
        option.requireEquipment =
          (option.target || "").indexOf("equipment_id") > 0;
        option.id = "option-" + Math.floor(Math.random() * 100000);
        option.label = this.$t(option.label);
        if (option.target == "/dashboard/screen/edit/:last") {
          option.href = option.target.replace(
            /:?last/,
            this.lastScreenId || "new"
          );
        } else {
          option.href = option.requireEquipment
            ? option.target.replace(/\:?equipment_id/, this.equipmentId)
            : option.target || ".";
        }
        option.type =
          option.href.indexOf("/dashboard") === 0 ? "panel" : "link";
        if (option?.options?.length) {
          (option.options || []).forEach((subItem) => {
            _parseOption(subItem);
          });
        }
      };
      var items = JSON.parse(JSON.stringify(this.items));
      return items
        .map((item) => {
          _parseOption(item);
          return item;
        })
        .filter((i) => {
          if (i.rule) {
            // please keep it in different lines for easy logging
            if (this.hidden_in_path.indexOf(this.$route.path) >= 0) {
              return false;
            }
            if (!this.hasPermission(i.rule)) return false;
          }
          if (i.requireEquipment && this.equipmentId == 0) {
            return false;
          }
          if (i.dependsOn) {
            let panel = this.openScreen?.panels?.find?.(
              (p) => p.name == i.dependsOn
            );
            if (panel?.view_permission?.length) {
              return panel.view_permission.some((id) =>
                this.$store.getters["user/hasUserAccessTo"](id)
              );
            }
          }
          return true;
        });
    }
  },
  methods: {
    toggleTreeview(id) {
      $(`#${id}`).slideToggle(300);
    },
    updateOpenScreen() {
      this.openScreen = this.$store.getters["dashboard/template"](
        this.$store.getters["dashboard/dashboardScreenId"]
      );
    },
    normalizeLabel(label) {
      return this.$utils.removeDiacritics(
        label
          .split(" ")
          .pop()
          .toLowerCase()
      );
    }
  },
  mounted() {
    $(document).on("click", ".sidebar-menu li a", function(e) {
      if (window.screen.width <= 768) {
        setTimeout(function() {
          $('[data-toggle="push-menu"]').pushMenu("toggle");
        }, 200);
      }
      return true;
    });
    this.$root.$on("dashboardReady", this.updateOpenScreen);
  }
};
</script>

<style scoped>
ul.sidebar-menu > li.divisor {
  border-top: 1px solid lightgray;
}
ul.sidebar-menu > li.disabled {
  cursor: not-allowed;
}
ul.sidebar-menu > li.disabled > a {
  pointer-events: none;
}
</style>
